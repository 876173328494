<template>
	<a v-if="url" :href="url" target="_blank" download class="ml-3 font-normal text-sm">
		(Datei-Download)
	</a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	components: {},
	layout: 'plain-company',
	props: {
		url: { type: String, required: true }
	}
});
</script>
